<template>
  <v-main>
    <AppBar :tabs="tabs" :buttons="buttons" @onUpdate="onUpdate"  />
    <Loading v-model="loading" />
    <v-container v-if="!loading">

      <v-breadcrumbs
        :items="path"
      ></v-breadcrumbs>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="First Name"
            solo
            hide-details="auto"   
            v-model="user.first_name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Last Name"
            solo
            hide-details="auto"
            v-model="user.last_name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Email Address"
            solo
            hide-details="auto"
            v-model="user.email"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            v-model="user.role"
            :items="roles"
            ref="rolesList"
            label="Role"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
            item-text="label"
            item-value="value"
          ></v-select>                
        </v-col>
        <v-col>
          <v-checkbox v-if="user.role == 'MANAGER'" v-model="user.all_access" color="error" label="Unrestricted module access to all locations, current and future"></v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="(user.role == 'MANAGER' || user.role == 'DEFAULT')  && !user.all_access">
        <v-col sm="12" md="6">
          <v-select
            :items="user.locations"
            ref="location"
            label="Location"
            solo
            background-color="#fff"
            hide-details="auto"
            item-text="name"
            item-value="id"
            return-object
            v-model="location"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-if="location && (user.role == 'MANAGER' || user.role == 'DEFAULT') && !user.all_access">
        <v-col cols="12">
          <!-- modules -->
          <v-card>
            <v-card-text class="text-left">
              <h3>General Modules <v-btn @click="toggleSelectedModules(location.pivot.modules)" class="float-right" right color="primary" small>{{modulesSelectAllLabel(location.pivot.modules)}}</v-btn></h3>
              <v-row>
                <v-col cols="12" md="2" sm="12" v-for="(mod, index) in modules" :key="index">
                  <v-checkbox @change="user.all_access = false" v-model="location.pivot.modules" :value="mod"  color="error" :label="mod"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Accounts -->
          <v-card v-if="location.pivot.modules.includes('accounts') && !user.all_access" >
            <v-card-text class="text-left mt-4">
              <h3>Accounts Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedAccountsActions(location.pivot.actions)">{{accountsSelectAllLabel(location.pivot.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_ACCOUNTS" :key="action">
                  <v-checkbox @change="user.all_access = false" v-model="location.pivot.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Users -->
          <v-card v-if="location.pivot.modules.includes('users') && !user.all_access">
            <v-card-text class="text-left mt-4">
              <h3>Staff Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedUsersActions(location.pivot.actions)">{{usersSelectAllLabel(location.pivot.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_USERS" :key="action">
                  <v-checkbox @change="user.all_access = false" v-model="location.pivot.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Locations -->
          <v-card v-if="location.pivot.modules.includes('locations') && !user.all_access">
            <v-card-text class="text-left mt-4">
              <h3>Locations Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedLocationsActions(location.pivot.actions)">{{locationsSelectAllLabel(location.pivot.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_LOCATIONS" :key="action">
                  <v-checkbox @change="user.all_access = false" v-model="location.pivot.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Plans -->
          <v-card v-if="location.pivot.modules.includes('plans') && !user.all_access">
            <v-card-text class="text-left mt-4">
              <h3>Plans Module <v-btn class="float-right" right color="primary" small @click="toggleSelectedPlansActions(location.pivot.actions)">{{plansSelectAllLabel(location.pivot.actions)}}</v-btn></h3>
              <v-row>                
                <v-col cols="12" md="2" sm="12" v-for="action in ACTIONS_PLANS" :key="action">
                  <v-checkbox @change="user.all_access = false"  v-model="location.pivot.actions" :value="action" color="error" :label="actionLabel(action)"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Email/SMS Templates -->
          <!-- <v-card v-if="location.pivot.modules.includes('emails') || location.pivot.modules.includes('sms')">
            <v-card-text class="text-left mt-4">              
              <v-row>
                <v-col cols="12" md="6" sm="12" v-if="location.pivot.modules.includes('emails')">
                  <h3>Email Templates Module</h3>
                  <v-checkbox @change="user.all_access = false" v-model="location.pivot.actions" value="emails.edit" color="error" label="Edit"></v-checkbox>
                </v-col>

                <v-col cols="12" md="6" sm="12" v-if="location.pivot.modules.includes('sms')">
                  <h3>SMS Templates Module</h3>
                  <v-checkbox @change="user.all_access = false" v-model="location.pivot.actions" value="sms.edit" color="error" label="Edit"></v-checkbox>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card> -->
          <!-- /Email/SMS Templates -->

        </v-col>

      </v-row>

    </v-container>
  </v-main>
</template>
<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
// const ACTIONS_ACCOUNTS = ['accounts.add', 'accounts.edit', 'accounts.deactivate', 'accounts.reactivate', 'accounts.delete']
// const ACTIONS_USERS = ['users.add', 'users.edit', 'users.deactivate', 'users.reactivate', 'users.delete']
// const ACTIONS_LOCATIONS = ['locations.add', 'locations.edit', 'locations.deactivate', 'locations.reactivate', 'locations.delete']
// const ACTIONS_PLANS = ['plans.add', 'plans.edit', 'plans.deactivate', 'plans.reactivate', 'plans.delete']
// const ACTIONS_EMAILS = ['emails.edit']
// const ACTIONS_SMS = ['sms.edit']
import {ACTIONS_ACCOUNTS, ACTIONS_USERS, ACTIONS_LOCATIONS, ACTIONS_PLANS, ACTIONS_EMAILS, ACTIONS_SMS, MODULES} from '../constants'
export default {
  components: {
    AppBar,
    Loading
  },
  
  data:() => ({
    MODULES,
    ACTIONS_ACCOUNTS,
    ACTIONS_USERS,
    ACTIONS_LOCATIONS,
    ACTIONS_PLANS,
    ACTIONS_EMAILS,
    ACTIONS_SMS,
    loading: true,
    success: null,
    error: null,
    org: null,
    user: null,
    roles: [
      {label: 'Super Administrator', value: 'SUPERADMIN'},
      {label: 'Administrator', value: 'ADMIN'},
      {label: 'Manager', value: 'MANAGER'},
      {label: 'Staff', value: 'DEFAULT'}
    ],
    // modules: ["accounts", "locations", "plans", "users", "pickup", "notify", "archive", "scan", "forward", "announcement", "placement", "emails", "sms", "reports"],
    actions: ["accounts.add", "accounts.edit", "accounts.deactivate", "accounts.reactivate", "accounts.delete", "locations.add", "locations.edit", "locations.deactivate", "locations.reactivate", "locations.delete", "plans.add", "plans.edit", "plans.deactivate", "plans.reactivate", "plans.delete", "users.add", "users.edit", "users.deactivate", "users.reactivate", "users.delete", "email_templates.edit", "sms_templates.edit", "emails.edit", "sms.edit"],
    location: null,
    buttons: [
      {label: 'UPDATE', click: 'onUpdate', color: 'primary', right: true, hidden: false}
    ]
  }),

  computed: {
    modules() {
      const modules = ["pickup", "notify", "scan", "forward", "placement", "archive", "accounts", "users", "locations", "plans", "emails"]
      if (this.location && this.location.has_sms) {
        modules.push('sms')
      }
      modules.push('reports')
      modules.push('announcement')
      return modules
    },
    tabs() {
      return [
        {title: 'Profile',  to: `/accounts/${this.$route.params.orgId}/profile`},
        {title: 'Locations',  to: `/accounts/${this.$route.params.orgId}/locations`},
        {title: 'Staff',  to: `/accounts/${this.$route.params.orgId}/staff`},
      ]
    },
    path() {
      const path = []
      path.push(
        {text: 'Accounts', href: '/accounts', disabled: false}
      )

      if (this.org) {
        path.push(
          {text: this.org.name, href: '/accounts/' + this.org.id + '/profile'}
        )
        path.push(
          {text: 'Staff', href: '/accounts/' + this.org.id + '/staff'}
        )
      }

      if (this.user) {
        path.push(
          {text: this.user.first_name + ' ' + this.user.last_name, disabled: true}
        )
      }

      return path
    },
  },

  mounted() {
    const { userId, orgId } = this.$route.params
    console.log(this.$route.params)
    this.loading = true
    this.$api.org.profile(orgId)
      .then(data => {
        this.org = data
      })
      .finally(() => {
        console.log(userId)
        this.$api.user.profile(orgId, userId)
          .then(user => {
            this.$api.location.list(orgId)
              .then(locations => {
                for(let i=0;i<locations.length;i++) {
                  let found = false
                  for(let j=0;j<user.locations.length;j++) {
                    if (user.locations[j].id === locations[i].id) {
                      found = true
                      continue
                    }
                  }
                  if (found == false) {
                    const location = locations[i]
                    const loc = Object.assign(location, {pivot: {actions: [], modules: [], location_id: location.id}})
                    user.locations.push(loc)
                  }
                }
              })
            this.user = user
          })
          .finally(() => {
            this.loading = false
          })
      })
  },

  methods: {
    onUpdate() {
      this.success = null
      this.error = null
      this.loading = true
      this.$api.user.update(this.org.id, this.user)
        .then(msg => {
          this.success = msg
        })
        .catch(msg => {
          this.error = msg
        })
        .finally(() => {
          this.loading = false
        })
    },
    modulesSelectAllLabel(userModules) {
      return this.isAllModulesSelected(userModules) ? 'Unselect All' : 'Select All'
    },
    isAllModulesSelected(userModules) {
      let allSelected = true
      for (let i=0;i<this.modules.length;i++) {
        if (!userModules.includes(this.modules[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedModules(userModules) {
      if (this.isAllModulesSelected(userModules)) {
        // Unselect
        this.location.pivot.modules.splice(0)
        // for(let i=0;i<this.modules.length;i++) {
        //   let index = this.modules.findIndex((element) => element == this.modules[i])
        //   if (index > -1) {
        //     userModules.splice(index, 1)
        //   }
        // }
      } else {
        // Select
        for (let i=0;i<this.modules.length;i++) {
          this.location.pivot.modules.push(this.modules[i])
          // let index = userModules.findIndex((element) => element == this.modules[i])
          // if (index == -1) {
          //   userModules.push(this.modules[i])
          // }
        }
      }
    },
    actionLabel(action) {
      console.log(action)
      if (action.includes('add')) return 'Add'
      if (action.includes('edit')) return 'Edit'
      if (action.includes('deactivate')) return 'Deactivate'
      if (action.includes('reactivate')) return 'Reactivate'
      if (action.includes('delete')) return 'Delete'
      return ''
    },
    // Accounts Select All Toggle
    accountsSelectAllLabel(userActions) {
      return this.isAllAccountsActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllAccountsActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_ACCOUNTS.length;i++) {
        if (!userActions.includes(this.ACTIONS_ACCOUNTS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedAccountsActions(userActions) {
      if (this.isAllAccountsActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_ACCOUNTS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_ACCOUNTS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_ACCOUNTS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_ACCOUNTS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_ACCOUNTS[i])
          }
        }
      }
    },
    // Users Select All Toggle
    usersSelectAllLabel(userActions) {
      return this.isAllUsersActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllUsersActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_USERS.length;i++) {
        if (!userActions.includes(this.ACTIONS_USERS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedUsersActions(userActions) {
      if (this.isAllUsersActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_USERS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_USERS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_USERS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_USERS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_USERS[i])
          }
        }
      }
    },
    // Locations Select All Toggle
    locationsSelectAllLabel(userActions) {
      return this.isAllLocationsActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllLocationsActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_LOCATIONS.length;i++) {
        if (!userActions.includes(this.ACTIONS_LOCATIONS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedLocationsActions(userActions) {
      if (this.isAllLocationsActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_LOCATIONS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_LOCATIONS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_LOCATIONS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_LOCATIONS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_LOCATIONS[i])
          }
        }
      }
    },
    // Plans Select All Toggle
    plansSelectAllLabel(userActions) {
      return this.isAllPlansActionsSelected(userActions) ? 'Unselect All' : 'Select All'
    },
    isAllPlansActionsSelected(userActions) {
      let allSelected = true
      for (let i=0;i<this.ACTIONS_PLANS.length;i++) {
        if (!userActions.includes(this.ACTIONS_PLANS[i])) {
          allSelected = false
          break
        }
      }
      return allSelected
    },
    toggleSelectedPlansActions(userActions) {
      if (this.isAllPlansActionsSelected(userActions)) {
        // Unselect
        for(let i=0;i<this.ACTIONS_PLANS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_PLANS[i])
          if (index > -1) {
            userActions.splice(index, 1)
          }
        }
      } else {
        // Select
        for (let i=0;i<this.ACTIONS_PLANS.length;i++) {
          let index = userActions.findIndex((element) => element == this.ACTIONS_PLANS[i])
          if (index == -1) {
            userActions.push(this.ACTIONS_PLANS[i])
          }
        }
      }
    },

  }

}
</script>
