<template>
  <v-main>
    <AppBar :tabs="tabs" :buttons="buttons" />
    <v-container>

      <v-row>
        <v-col>
          <v-alert v-if="confirmation" type="success" dismissible>Congratulation, the account has been created successfully.</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
              hide-no-data
              hide-details
              label="Search.."
              solo
              return-object
              clearable
              auto-select-first
              autofocus
              append-icon="mdi-magnify"
              v-model="search"
            ></v-text-field>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-left">Accounts</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table 
            class="elevation-2"
            :headers="headers"
            :items="filteredAccounts"
            :search="search"
            item-key="id"
            sort-by="name"
            :loading="loading"
          >
            <template v-slot:[`item.id`]="{ item }">
              <v-btn :to="'/accounts/'+item.id+'/profile'" small color="primary" >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

import AppBar from '@/components/general/AppBar.vue'
export default {
  name: 'Accounts',

  components: {
    AppBar
  },

  data:() => ({
    buttons: [
      {label: 'ADD ACCOUNT', to: '/org/create/step1?new', color: 'primary', right: true, hidden: false}
    ],
    accounts: [],
    filteredAccounts: [],
    search: null,
    headers: [
      {text: 'Account Name', value: 'name'},
      {text: 'No. of Locations', value: 'locations_count'},
      {text: 'No. of Units', value: 'active_accounts_count'},
      {text: 'No. of Staff', value: 'users_count'},
      {text: 'Action', value: 'id'}
    ],
    tabs: [
      { title: "Active", to: "/accounts?active" },
      { title: "Deactivated",  to: "/accounts?deactivated" },
    ],
    loading: false
  }),

  computed: {
    status() {
      let status = 'INACTIVE'
      if (typeof this.$route.query.deactivated === 'undefined') {
        status = 'ACTIVE'
      }
      return status
    },
    confirmation() {
      let confirmation
      if (typeof this.$route.query.success === 'undefined') {
        confirmation = false
      } else {
        confirmation = true
      }
      return confirmation
    }
  },

  watch: {
    status() {
      this.filterAccounts()
    }
  },

  mounted() {
    this.loading = true
    console.log('accounts')
    this.$api.org.list()
      .then(data => {
        this.accounts = data
        this.filterAccounts()
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    filterAccounts() {
      const accounts = this.accounts.filter(item => item.status == this.status)
      this.filteredAccounts = accounts
    }
  }
}
</script>
