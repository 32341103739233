<template>
  <v-main>
    <AppBar :tabs="tabs" :buttons="buttons" @onAdd="onAdd"  />
    <Loading v-model="loading" />
    <v-container v-if="!loading">

    <v-dialog
      v-model="confirm"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h7">
          Are you sure you want to delete this staff ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            small
            @click="confirmDelete"
          >
            Confirm
          </v-btn>

          <v-btn
            color="error"
            small
            @click="confirm = false; toBeDeleted = null"
          >
            Cancel
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>    

      <v-breadcrumbs
        :items="path"
      ></v-breadcrumbs>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            class="elevation-2"
            :items="users"
            :headers="headers"
          >
            <template v-slot:[`item.id`]="{ item }">
              <div class="text-left">

              <v-btn small color="primary" :to="'/accounts/'+org.id+'/staff/'+item.id" >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

              <v-btn v-if="!item.primary" @click="deleteUser(item.id)" small color="error"  class="ml-2">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>                            

              </div>
            </template>
          
          </v-data-table>
        </v-col>
      </v-row>

    </v-container>
  </v-main>
</template>
<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    AppBar,
    Loading
  },
  
  data:() => ({
    confirm: false,
    toBeDeleted: null,
    loading: true,
    org: null,
    users: [],
    headers: [
      {text: 'First Name', value: 'first_name'},
      {text: 'Last Name', value: 'last_name'},
      {text: 'Email Address', value: 'email'},
      {text: 'Actions', value: 'id'}
    ],
    success: null,
    error: null
  }),

  computed: {
    tabs() {
      return [
        {title: 'Profile',  to: `/accounts/${this.$route.params.orgId}/profile`},
        {title: 'Locations',  to: `/accounts/${this.$route.params.orgId}/locations`},
        {title: 'Staff',  to: `/accounts/${this.$route.params.orgId}/staff`},
      ]
    },
    path() {
      const path = []
      path.push(
        {text: 'Accounts', href: '/accounts', disabled: false}
      )

      if (this.org) {
        path.push(
          {text: this.org.name, disabled: true}
        )
      }

      return path
    },
    buttons() {
      const btn = []
        btn.push(
          {label: 'ADD STAFF', click: 'onAdd', color: 'primary', right: true, hidden: false}
        )
      return btn
    }    
  },

  mounted() {
    this.loadUsers()
  },

  methods: {
    onAdd() {
      this.$router.push('/accounts/' + this.$route.params.orgId + '/staff/create')
    },
    loadUsers() {
      this.loading = true
      this.$api.org.profile(this.$route.params.orgId)
        .then(data => {
          this.org = data
        })
        .finally(() => {
          this.$api.user.list(this.org.id)
            .then(data => {
              this.users = data
            })
            .finally(() => {
              this.loading = false
            })
        })

    },
    deleteUser(id) {
      this.toBeDeleted = id
      this.confirm = true
    },
    confirmDelete() {
      const id = this.toBeDeleted
      this.loading = true
      this.success = null
      this.error = null
      this.$api.user.delete(id)
        .then((data) => {
          this.success = data
          this.loadUsers()
        })
        .catch((data) => {
          this.error = data
        })
        .finally(() => {
          this.loading = false
          this.confirm = false
          this.toBeDeleted = null
        })

    }
  }

}
</script>
