<template>
  <v-main>
    <AppBar :tabs="tabs" :buttons="buttons" @onUpdate="onUpdate" />
    <Loading v-model="loading" />
    <v-container>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row v-if="profile">
        <v-col>
          <v-text-field
            label="Sender Name"
            placeholder="Sender Name"
            solo
            hide-details="auto"
            v-model="profile.sender_name"  
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Sender Email Address"
            solo
            hide-details="auto"   
            v-model="profile.sender_email"
          ></v-text-field>
        </v-col>
      </v-row>

      <h3 v-if="profile" class="text-left mt-7 mb-2">Signature</h3>

      <v-row v-if="profile">
        <v-col>
              <v-card class="elevation-2">
                <!-- <editor v-model="profile.signature" /> -->
                <vue-editor v-model="profile.signature" />
              </v-card>
          
        </v-col>
      </v-row>

      
    </v-container>
  </v-main>
</template>

<script>
// import Editor from '@/components/Editor.vue'
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
import { VueEditor } from "vue2-editor"
export default {
  name: 'EmailTemplates',

  components: {
    AppBar,
    Loading,
    // Editor,
    VueEditor
  },

  data:() => ({
    buttons: [
      {label: 'UPDATE', click: 'onUpdate', color: 'primary', right: true, hidden: false}
    ],
    profile: null,
    tabs: [
      { title: "Templates", to: "/email/templates" },
      { title: "Profile",  to: "/email/profile" },
    ],
    loading: true,
    success: null,
    error: null
  }),

  mounted() {
    this.loading = true
    this.$api.email.profile()
      .then(data => {
        this.profile = data
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    onUpdate() {
      const profile = this.profile
      this.loading = true
      this.error = null
      this.success = null
      this.$api.email.updateProfile(profile)
        .then((msg) => this.success = msg)
        .catch((msg) => this.error = msg)
        .finally(() => this.loading = false)
    }
  }
}
</script>
