<template>
  <v-main>
    <AppBar :tabs="tabs" :buttons="buttons" @onDeactivate="onDeactivate" @onReactivate="onReactivate" @onUpdate="onUpdate" />
    <Loading v-model="loading" />
    <v-container v-if="!loading">

      <v-breadcrumbs
        :items="path"
      ></v-breadcrumbs>

      <v-form v-model="valid">

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="text-left">Account Profile</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Account Name"
            placeholder="Account Name"
            solo
            hide-details="auto"   
            v-model="org.name"
            :rules="rules.name"    
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            solo
            label="Notes"
            v-model="org.notes"
          ></v-textarea>          
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="text-left">Primary Contact</h3>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="First Name"
            solo
            hide-details="auto"     
            v-model="org.superadmin.first_name"
            :rules="rules.first_name"  
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Last Name"
            solo
            hide-details="auto"   
            v-model="org.superadmin.last_name"
            :rules="rules.last_name"  
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            label="Email Address"
            solo
            hide-details="auto"
            v-model="org.superadmin.email"
            :rules="rules.email"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Phone Number (Optional)"
            solo
            hide-details="auto"
            v-model="org.superadmin.phone"        
          ></v-text-field>
        </v-col>
      </v-row>

      </v-form>

      <v-row class="mt-9">
        <v-col cols="12" sm="12">
          <h3 class="text-left">Add-ons</h3>
        </v-col>
        <v-col class="text-left" cols="12" sm="6">
          <v-select
            v-model="org.modules"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details
            multiple
            label="addons"
            :items="['forward', 'scan', 'billing']"
            validate-on-blur
          ></v-select>

        </v-col>
      </v-row>

      <v-row class="mt-9">
        <v-col>
          <h3 class="text-left">Files</h3>
        </v-col>
        <v-col class="text-right">
          <v-btn :disabled="!selectedFiles.length" @click.stop="deleteFiles" small color="error"  class="mr-2">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>                            
          <v-btn color="primary" @click="enableFileUpload = !enableFileUpload" small>ADD FILE</v-btn>
        </v-col>
      </v-row>

      <v-row v-if="enableFileUpload">
        <v-col>
          <v-file-input
            label="File Upload"
            truncate-length="15"
            @change="onFileInput"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            class="elevation-2"
            show-select
            selectable-key="id"
            :items="org.files"
            :headers="filesHeaders"
            v-model="selectedFiles"
          >
            <template v-slot:[`item.name`]="{ item }">
              <a target="_blank" :href="item.url">{{item.name}}</a>
            </template>

          </v-data-table>
        </v-col>
      </v-row>



    </v-container>
  </v-main>
</template>
<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
export default {
  components: {
    AppBar,
    Loading
  },

  data:() => ({
    org: null,
    valid: true,
    loading: true,
    error: null,
    success: null,
    enableFileUpload: false,
    filesHeaders: [
      {text: 'File Name', value: 'name'},
      {text: 'Size', value: 'size'}
    ],
    selectedFiles: []
  }),

  computed: {
    tabs() {
      return [
        {title: 'Profile',  to: `/accounts/${this.$route.params.orgId}/profile`},
        {title: 'Locations',  to: `/accounts/${this.$route.params.orgId}/locations`},
        {title: 'Staff',  to: `/accounts/${this.$route.params.orgId}/staff`},
      ]
    },
    path() {
      const path = []
      path.push(
        {text: 'Accounts', href: '/accounts', disabled: false}
      )

      if (this.org) {
        path.push(
          {text: this.org.name, disabled: true}
        )
      }

      return path
    },
    buttons() {
      const items = []
      if (!this.org) return items

      if (this.org.status.toUpperCase() == 'ACTIVE') {
        items.push({label: 'DEACTIVATE', click: 'onDeactivate', color: 'error', right: true, hidden: false})
      }
      if (this.org.status.toUpperCase() == 'INACTIVE') {
        items.push({label: 'REACTIVATE', click: 'onReactivate', color: 'primary', right: true, hidden: false, disabled: false})
      }

      items.push({label: 'UPDATE', click: 'onUpdate', color: 'primary', right: true, hidden: false, disabled: !this.valid})

      
      return items
    },
    rules() {
      const rules = {
        first_name: [],
        last_name: [],
        email: [],
        name: []
      }

      if (!this.org) return rules
            
      rules.name.push(
        v => !!v || 'Account Name is required',
      )
      
      rules.first_name.push(
        v => !!v || 'First Name is required',
      )
      rules.last_name.push(
        v => !!v || 'Last Name is required',
      )
      rules.email.push(
        v => !!v || 'Email is required',
        v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),
      )

      return rules
    },
  },

  mounted() {
    this.loading = true
    this.$api.org.profile(this.$route.params.orgId)
      .then(data => {
        this.org = data
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    onDeactivate() {
      this.loading = true
      this.$api.org.deactivate(this.org.id)
        .then(() => {
          this.org.status = 'INACTIVE'
        })
        .finally(() => {
          this.loading = false
        })
    },
    onReactivate() {
      this.loading = true
      this.$api.org.reactivate(this.org.id)
        .then(() => {
          this.org.status = 'ACTIVE'
        })
        .finally(() => {
          this.loading = false
        })
    },
    onUpdate() {
      this.error = null
      this.success = null
      this.loading = true
      const org = {
        name: this.org.name,
        notes: this.org.notes,
        superadmin: {
          first_name: this.org.superadmin.first_name,
          last_name: this.org.superadmin.last_name,
          email: this.org.superadmin.email,
          phone: this.org.superadmin.phone
        },
        modules: this.org.modules
      }

      this.$api.org.update(this.org.id, org)
        .then((data) => {
          // console.log(data)
          this.success = data
        })
        .catch((data) => {
          // console.log(data)
          this.error = data.message
        })
        .finally(() => {
          this.loading = false
        })
    },
    onFileInput(file) {
      this.loading = true
      this.success = null
      this.error = null
      if (!file) return
      let formData = new FormData()
      formData.append('file', file)
      this.$api.org.upload(this.org.id, formData)
        .then(data => {
          this.success = data
        })
        .catch(msg => {
          this.error = msg
        })
        .finally(() => {
          this.$api.org.profile(this.org.id)
            .then(data => {
              this.org = data
            })
            .finally(() => {
              this.loading = false
            })
        })
    },
    deleteFiles() {
      if (!this.selectedFiles.length) return
      this.loading = true
      this.success = null
      this.error = null
      const ids = this.selectedFiles.map(item => item.id)
      console.log(ids)
      this.$api.org.deleteFiles(this.org.id, {ids})
        .then(msg => {
          this.success = msg
        })
        .catch(msg => {
          this.error = msg
        })
        .finally(() => {
          this.$api.org.profile(this.org.id)
            .then(data => {
              this.org = data
            })
            .finally(() => {
              this.loading = false
            })
        })
    }
  }
}
</script>