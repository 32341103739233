<template>
  <v-main>
    <AppBar 
      :tabs="tabs" 
      :buttons="buttons" 
      @onUpdate="onUpdate" 
      @onSave="onSave"
      @onAdd="onAdd"
    ></AppBar>
    <Loading v-model="loading" />

    <v-dialog
      v-model="confirm"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h7">
          Are you sure you want to delete this location ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            small
            @click="confirmDelete"
          >
            Confirm
          </v-btn>

          <v-btn
            color="error"
            small
            @click="confirm = false; toBeDeleted = null"
          >
            Cancel
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>    

    <v-container v-if="!loading">

      <v-breadcrumbs
        :items="path"
      ></v-breadcrumbs>

      <v-row>
        <v-col>
          <v-alert v-if="resend" type="success" dismissible>{{resend}}</v-alert>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-form v-model="valid">

      <v-row v-if="isAdd || isEdit">
        <v-col sm="8">
          <v-text-field
            label="Location Name"
            solo
            hide-details="auto"   
            v-model="location.name"
            :rules="rules.name"    
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <v-text-field
            label="Maximum Units"
            solo
            hide-details="auto"   
            v-model="location.max_accounts"
          ></v-text-field>
          <div v-if="isEdit" style="width:100%" class="text-left">{{location.active_accounts_count}}/{{location.max_accounts ? location.max_accounts : 'unlimited '}} accounts</div>
        </v-col>
      </v-row>

      <v-row v-if="isAdd || isEdit">
        <v-col cols="12" sm="12">
          <v-textarea
            v-model="location.address"
            ref="address"
            label="Address"
            background-color="#fff"
            class="elevation-2"
            hide-details="auto"
            solo
          ></v-textarea>
        </v-col>
      </v-row>


      <v-row v-if="isAdd || isEdit">
        <v-col sm="6">
          <div style="width:100%">
          <v-text-field
            label="Sender Name"
            solo
            hide-details="auto"   
            v-model="location.email_name"
            :rules="rules.email_name"    
          ></v-text-field>
          </div>
        </v-col>

        <v-col sm="6">
          <v-text-field
            label="Sender Email"
            solo
            hide-details="auto"   
            v-model="location.email_from"
            :rules="rules.email_from"
          ></v-text-field>
          <div class="text-left" v-if="isEdit && !location.verified_email" style="width:100%"><a @click="resendVerificationEmail" href="">Re-send verification email</a></div>
        </v-col>
      </v-row>


      <v-row v-if="isAdd || isEdit">
        <v-col md="6" sm="12">
        <!-- <v-checkbox v-model="location.new_unit_enabled" label="Allow unit creation" :value="1"></v-checkbox>           -->
        <v-checkbox v-model="location.has_sms" label="Notify by SMS"></v-checkbox>          
        </v-col>
      </v-row>

       <v-row v-if="isAdd & location.has_sms">
          <v-col cols="12" md="6" sm="12">
              <v-select
                v-model="country"
                :items="['Canada', 'US']"
                label="Country"
                filled
                background-color="#fff"
                class="elevation-2 bg-white"
                hide-details="auto"
              ></v-select>
          </v-col>
          <v-col cols="12" md="6" sm="12">
              <v-select
                v-model="state"
                :items="states"
                label="State"
                filled
                background-color="#fff"
                class="elevation-2 bg-white"
                hide-details="auto"
                item-text="name"
                item-value="abbreviation"
                @change="onStateChange"
              ></v-select>
          </v-col>
      </v-row>

      <v-row v-if="isAdd & location.has_sms">
        <v-col>
          <!--
          <v-select
            v-model="city"
            :items="cities"
            label="City"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
            item-text="city"
            item-value="city"
            @change="onCityChange"
          ></v-select>
          -->
     <v-autocomplete
        v-model="city"
        :items="cities"
        color="white"
        hide-no-data
        hide-selected
        item-text="city"
        item-value="city"
        label="City"
        placeholder="Start typing to Search"
        filled
        @change="onCityChange"
        background-color="#fff"
        class="elevation-2 bg-white"
        hide-details="auto"

      ></v-autocomplete>          
        </v-col>
      </v-row>


      <v-row v-if="(isAdd || isEdit) & location.has_sms">
        <v-col sm="6">
          <v-text-field
            label="Twilio Number"
            solo
            hide-details="auto"
            v-model="location.phone_number"
            disabled
            v-if="isEdit"
          ></v-text-field>
          <v-select
            v-else
            v-model="location.phone_number"
            :items="phoneNumbers"
            label="Twilio Number"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
          ></v-select>

        </v-col>
      </v-row>



      <v-row v-if="isAdd || isEdit">
        <v-col cols="12" class="text-right">
          <v-btn text="" color="error" @click="onCancel" >
            <v-icon left>mdi-minus</v-icon> Cancel
          </v-btn>
        </v-col>
      </v-row>

      <!-- <v-row><v-col><v-divider></v-divider></v-col></v-row> -->

      <v-row v-if="isEdit & location.has_sms">
        <v-col md="4" sm="12">
          <v-file-input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="Import Units"
            v-model="file"
            @change="onFileInput"
            :clearable="false"
          ></v-file-input>
        </v-col>
        <v-col class="text-left">
          <a href="https://api.dash.test.thenetworkhub.net/sample-import.csv" target="_blank">Import Template</a>
        </v-col>
      </v-row>

      <v-row v-if="isEdit & location.has_sms">
        <v-col md="4" sm="12">
          <v-text-field
            label="Unit Name"
            solo
            hide-details="auto"   
            v-model="account.name"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="12">
          <v-text-field
            label="First Name"
            solo
            hide-details="auto"   
            v-model="account.first_name"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="12">
          <v-text-field
            label="Last Name"
            solo
            hide-details="auto"   
            v-model="account.last_name"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="isEdit & location.has_sms">
        <v-col md="4" sm="12">
          <v-text-field
            label="Mobile Number"
            solo
            hide-details="auto"   
            v-model="account.phone"
            :rules="rules.phone"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="12">
          <v-text-field
            label="Email Address"
            solo
            hide-details="auto"   
            v-model="account.email"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="12">
          <v-select
            v-model="account.notify_by"
            :items="['email', 'sms']"
            ref="rolesList"
            label="Notification Method"
            filled
            background-color="#fff"
            class="elevation-2 bg-white"
            hide-details="auto"
            multiple
          ></v-select>                
        </v-col>
      </v-row>

      <v-row v-if="isEdit">
        <v-col class="text-right">
          <v-btn @click="addUnit" color="primary" class="text-right" small>Add Unit</v-btn>
        </v-col>
      </v-row>

      <!-- <v-row><v-col><v-divider></v-divider></v-col></v-row> -->

      <v-row v-if="isEdit && units.length">
        <v-col>
          <h3 class="text-left">Units</h3>
        </v-col>
      </v-row>

      <v-row v-if="isEdit && units.length">
        <v-col md="4" sm="12" v-for="unit in units" :key="unit.id">
          <v-checkbox :disabled="unit.onhand_items_count > 0" v-model="selectedUnits" :label="unit.account_name" :value="unit.id"></v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="isEdit && units.length">
        <v-col class="text-right">
          <v-btn :disabled="!selectedUnits.length" @click="onDeactivate" color="error" class="text-right" small>Deactivate</v-btn>
        </v-col>
      </v-row>



      </v-form>

      <v-row class="mt-3">
        <v-col>
          <v-data-table
            class="elevation-2"
            :items="locations"
            :headers="headers"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{item.name}} 
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="!item.verified_email"
              >
                Unverified
              </v-chip>
            </template>
            <template v-slot:[`item.max_accounts`]="{ item }">
              {{item.active_accounts_count}}/{{item.max_accounts ? item.max_accounts : 'Unlimited'}} 
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <div class="text-left">
              <v-btn small class="mr-2" color="primary" @click="setEdit(item)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

              <v-btn @click="deleteLocation(item)" small color="error"  class="mr-2">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>                            

              </div>
            </template>
          
          </v-data-table>
        </v-col>
      </v-row>

    </v-container>
  </v-main>
</template>
<script>
import AppBar from '@/components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
import {US_STATES, CANADA_STATES} from '../../helpers/states'
export default {
  components: {
    AppBar,
    Loading
  },
  
  data:() => ({
    confirm: false,
    toBeDeleted: null,
    phoneNumbers: [],
    cities: [],
    country: null,
    state: null,
    city: null,
    canadaStates: CANADA_STATES,
    usStates: US_STATES,
    selectedUnits: [],
    loading: true,
    org: null,
    locations: [],
    headers: [
      {text: 'Location Name', value: 'name'},
      {text: 'No. of Units', value: 'max_accounts'},
      {text: 'No. of Staff', value: 'users_count'},
      {text: 'Actions', value: 'id'}
    ],
    rules: {
      name: [v => !!v || 'Location Name is required'],
      email_name: [v => !!v || 'Sender Name is required'],
      email_from: [
        v => !!v || 'Sender Email is required',
        v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),
      ],
      phone: [
        v => !v ? true : (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) || 'Phone number is not valid')
      ],
    },
    valid: false,
    location: {
      name: null,
      max_accounts: null,
      email_name: null,
      email_from: null,
      new_unit_enabled: true,
      phone_number: null,
      has_sms: false,
      address: null
    },
    isAdd: false,
    isEdit: false,
    success: null,
    error: null,
    resend: null,
    account: {
      name: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      notify_by: []
    },
    file: null
  }),

  computed: {
    units() {
      if (!this.location) return []
      return this.location.accounts.filter(a => a.status == 'ACTIVE')
    },
    states() {
      return this.country === 'US' ? this.usStates : this.canadaStates
    },
    tabs() {
      return [
        {title: 'Profile',  to: `/accounts/${this.$route.params.orgId}/profile`},
        {title: 'Locations',  to: `/accounts/${this.$route.params.orgId}/locations`},
        {title: 'Staff',  to: `/accounts/${this.$route.params.orgId}/staff`},
      ]
    },
    path() {
      const path = []
      path.push(
        {text: 'Accounts', href: '/accounts', disabled: false}
      )

      if (this.org) {
        path.push(
          {text: this.org.name, disabled: true}
        )
      }

      return path
    },
    buttons() {
      const btn = []
      if (this.isAdd || this.isEdit) {
        btn.push(
          {label: this.isEdit ? 'UPDATE' : 'SAVE', click: this.isEdit ? 'onUpdate' : 'onSave', color: 'primary', right: true, hidden: false, disabled: !this.valid}
        )
      } else {
        btn.push(
          {label: 'ADD Location', click: 'onAdd', color: 'primary', right: true, hidden: false}
        )
      }
      return btn
    }
  },

  mounted() {
    this.loadLocations()
  },

  methods: {
    deleteLocation(item) {
      this.toBeDeleted = item.id
      this.confirm = true      
    },
    confirmDelete() {
      const id = this.toBeDeleted
      this.confirm = false
      this.loading = true
      this.$api.location.delete(id)
        .finally(() => {
          this.loading = false
          this.loadLocations()
        })
    },
    onStateChange() {
      const data = {
        country: this.country,
        state: this.state,
      }
      this.loading = true
      this.success = null
      this.error = null
      this.$api.location.cities(data)
        .then((data) => {
          console.log(data)
          this.cities = data
        })
        .catch((msg) => {
          this.error = msg
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCityChange() {
      const data = {
        country: this.country,
        state: this.state,
        city: this.city
      }
      this.loading = true
      this.success = null
      this.error = null
      this.$api.location.lookupPhoneNumbers(data)
        .then((data) => {
          console.log(data)
          this.phoneNumbers = data
        })
        .catch((msg) => {
          this.error = msg
        })
        .finally(() => {
          this.loading = false
        })
    },
    onFileInput(file) {
      console.log('onFileInput', file)
      if (typeof file === 'undefined') return
      if (!file) return
      this.loading = true
      this.success = null
      this.error = null
      let formData = new FormData()
      formData.append('file', file)
      this.$api.location.importCsv(this.location.id, formData)
        .then((data) => {
          this.success = data
          // this.loadLocations()
          this.$router.go()
        })
        .catch((data) => {
          this.error = data
          this.loading = false
        })
      
    },
    addUnit() {
      const locationId = this.location.id
      const account = this.account
      this.loading = true
      this.success = null
      this.error = null
      this.$api.location.createAccount(locationId, account)
        .then((data) => {
          this.success = data
          // this.loadLocations()
          this.$router.go()
        })
        .catch((data) => {
          this.error = data
          this.loading = false
        })
    },
    onDeactivate() {
      this.loading = true
      this.success = null
      this.error = null
      const ids = this.selectedUnits
      this.$api.location.deactivateAccounts({ids})
        .then((data) => {
          this.success = data
          this.onCancel()
          // this.loadLocations()
          this.$router.go()
        })
        .catch((data) => {
          this.error = data
        })
        .finally(() => {
          this.loading = false
        })
    },
    resendVerificationEmail() {
      this.loading = true
      this.resend = null
      this.$api.resendVerificationEmail(this.location.id)
        .then((data) => {
          this.resend = data
        })
        .catch((data) => {
          this.error = data
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadLocations() {
      this.loading = true
      this.$api.org.profile(this.$route.params.orgId)
        .then(data => {
          this.org = data
        })
        .finally(() => {
          this.$api.location.list(this.org.id)
            .then(data => {
              this.locations = data
            })
            .finally(() => {
              this.loading = false
            })
        })
    },
    setEdit(location) {
      this.$router.push('/accounts/' + this.$route.params.orgId + '/locations/' + location.id)
      // this.location = location
      // this.isEdit = true
    },
    onCancel() {
      this.isAdd = false
      this.isEdit = false
      this.location = {
        name: null,
        max_accounts: null,
        email_name: null,
        email_from: null,
        new_unit_enabled: true,
        phone_number: null,
        has_sms: false,
        address: null
      }
      this.account = {
        name: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        notify_by: []
      }
      this.phoneNumbers = []
      this.state = null
      this.country = null
      this.city = null
    },
    onAdd() {
      this.location = {
        name: null,
        max_accounts: null,
        email_name: null,
        email_from: null,
        new_unit_enabled: true,
        phone_number: null,
        has_sms: false,
        address: null
      }
      this.account = {
        name: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        notify_by: []
      }
      this.isAdd = true
      this.phoneNumbers = []
      this.state = null
      this.country = null
      this.city = null
    },
    onUpdate() {
      this.success = null
      this.error = null
      this.loading = true
      const location = this.location
      delete location.phone_number
      this.$api.location.update(this.org.id, location)
        .then(msg => {
          this.success = msg
        })
        .catch(msg => {
          this.error = msg
        })
        .finally(() => {
          this.onCancel()
          this.loadLocations()
        })
    },
    onSave() {
      this.success = null
      this.error = null
      this.loading = true
      this.$api.location.create(this.org.id, this.location)
        .then(msg => {
          this.success = msg
        })
        .catch(msg => {
          this.error = msg
        })
        .finally(() => {
          this.onCancel()
          this.loadLocations()
        })
    }
  }

}
</script>
