<template>
  <v-main>
    <AppBar :tabs="tabs" :buttons="buttons" @onUpdate="onUpdate" />
    <Loading v-model="loading" />
    <v-container>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-row>
            <v-col>
              <h3 class="text-left">Templates</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select                
                v-model="template"
                :items="templates"
                label="Email Template"
                filled
                background-color="#fff"
                class="elevation-2 bg-white"
                hide-details="auto"
                item-text="label"
                item-value="id"
                return-object
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="template">
            <v-col>
              <v-text-field
                label="Subject"
                placeholder="Subject"
                solo
                hide-details="auto"   
                v-model="template.subject"
              ></v-text-field>

            </v-col>
          </v-row>

          <v-row v-if="template">
            <v-col>
              <v-card class="elevation-2">
                <vue-editor v-model="template.body"/>
              </v-card>

            </v-col>
          </v-row>

          <v-row v-if="template">
            <v-col>
              <h3 class="text-left">Shortcodes</h3>
            </v-col>
          </v-row>

          <v-row v-if="template">
            <v-col>
              <ul>
                <li class="text-left" v-for="shortcode in template.shortcodes" :key="shortcode.id">%{{shortcode.name}}% - {{shortcode.label}}</li>
              </ul>
            </v-col>
          </v-row>

        </v-col>


        <v-col cols="12" sm="12" md="6" v-if="template">
          <v-row>
            <v-col>
              <h3 class="text-left">Output</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4 style="font-size:20px;font-weight:bold" class="text-left">Subject</h4>
            </v-col>
            <v-col cols="12" class="text-left">
              {{template.subject}}
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4 style="font-size:20px;font-weight:bold" class="text-left">Body</h4>
            </v-col>
            <v-col cols="12" class="text-left template-preview" v-html="preview">

            </v-col>
          </v-row>
          

        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

import AppBar from '@/components/general/AppBar.vue'
// import Editor from '@/components/Editor.vue'
import Loading from '@/components/Loading.vue'
import { VueEditor } from "vue2-editor"
export default {
  name: 'EmailTemplates',

  components: {
    AppBar,
    // Editor,
    Loading,
    VueEditor
  },

  data:() => ({
    buttons: [
      {label: 'UPDATE', click: 'onUpdate', color: 'primary', right: true, hidden: false}
    ],
    templates: [],
    template: null,
    tabs: [
      { title: "Templates", to: "/email/templates" },
      { title: "Profile",  to: "/email/profile" },
    ],
    loading: true,
    success: null,
    error: null,
    profile: null
  }),

  computed: {
    preview() {
      const preview = this.template.body.replace('%signature%', this.profile.signature)
      return preview
    }
  },

  mounted() {
    this.loading = true
    this.$api.email.templates()
      .then(data => {
        this.templates = data
      })
      .finally(() => {
        this.loading = false
      })
    this.$api.email.profile()
      .then(data => {
        this.profile = data
      })
      .finally(() => {
        this.loading = false
      })    
  },

  methods: {
    onUpdate() {
      const templates = this.templates
      this.loading = true
      this.error = null
      this.success = null
      this.$api.email.update(templates)
        .then((msg) => this.success = msg)
        .catch((msg) => this.error = msg)
        .finally(() => this.loading = false)
    }
  }
}
</script>
<style>
.template-preview p {
  margin-bottom: 0 !important;
}
</style>